<mat-form-field
  id="date"
  [appearance]="appearance"
  [hideRequiredMarker]="hideRequiredMarker"
  [ngClass]="formFieldClass()"
>
  <mat-label *ngIf="!hideLabel">{{ label }}</mat-label>
  <input
    matInput
    [formControl]="form.controls.date"
    [matDatepicker]="picker"
    [min]="_minDate()"
    [max]="_maxDate()"
    [required]="required"
    (blur)="onBlur.emit()"
  />
  <mat-hint *ngIf="!shouldHideHint()">MM/DD/YYYY</mat-hint>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker (closed)="onBlur.emit()" #picker></mat-datepicker>

  <mat-error *ngIf="errorFromInput() && displayError">{{
    errorFromInput()
  }}</mat-error>
  <mat-error *ngIf="showMinDateError()">
    Date must be on or after {{ _minDate() | date: "shortDate" : "UTC" }}
  </mat-error>
  <mat-error *ngIf="showMaxDateError()">
    Date must be on or before
    {{ _maxDate() | date: "shortDate" : "UTC" }}</mat-error
  >
  <mat-error *ngIf="showRequiredError()">Date is required</mat-error>
  <mat-error *ngIf="showInvalidFormatError()">
    <span>Invalid date format.</span>
    <br />
    <span>Please use MM/DD/YYYY</span>
  </mat-error>
</mat-form-field>
