import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { requireAppCheck } from '@jfw-library/shared/app-check';
import {
  convertUnixTimestampToPrettyDate,
  getOrganizerFromEvent,
} from 'business-logic';
import { CommunicationQueue, Event, Site } from 'common-types';
import { catchError, firstValueFrom, from, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedCommunicationQueueService {
  constructor(
    @Inject('environment')
    private environment: any,
    private httpClient: HttpClient,
  ) {}

  private reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  public async queueEmailMemberInvite(
    event: Event,
    eventMemberEmail: string,
    memberFirstName: string | undefined,
    memberLastName: string | undefined,
    isOrganizer: boolean = false,
  ): Promise<Partial<CommunicationQueue>> {
    const inviteUrl: string = `${this.environment.ecom_prefix_for_email_and_sms_communication}/invite/${event.id}/${eventMemberEmail}`;
    const organizer = getOrganizerFromEvent(event);
    const organizerDisplayName =
      `${organizer?.firstName} ${organizer?.lastName}`.trim();
    console.log({ organizer, organizerDisplayName });
    const queueData: Partial<CommunicationQueue> = {
      recipient: eventMemberEmail,
      communicationMethod: 'email',
      messageType: isOrganizer ? 'organizer-invite' : 'member-invite',
      // origin: 'ecom/queueEmailMemberInvite/communication-queue.service',
      origin: `${this.environment.site}/queueEmailMemberInvite/communication-queue.service`,
      templateData: {
        organizer: {
          organizerUserId: event.createdByUserId ?? '',
          firstName: organizerDisplayName,
          lastName: '',
        },
        event: {
          eventName: event.eventName,
          eventDate: convertUnixTimestampToPrettyDate(event.eventDate),
        },
        member: {
          firstName: memberFirstName,
          lastName: memberLastName,
        },
        inviteUrl: inviteUrl,
      },
    };

    return this.sendToCommunicationQueue(queueData);
  }

  public async queueEmailCoOwnerInvite(
    event: Event,
    eventMemberEmail: string,
    coOwnerFirstName: string | undefined,
    coOwnerLastName: string | undefined,
    coOwnerPhone: string | undefined,
  ): Promise<Partial<CommunicationQueue>> {
    let inviteUrl: string = `${this.environment.ecom_prefix_for_email_and_sms_communication}/invite/${event.id}/${eventMemberEmail}`;
    const organizer = getOrganizerFromEvent(event);
    const organizerDisplayName =
      `${organizer?.firstName} ${organizer?.lastName}`.trim();

    console.log(`organizer display name: ${organizerDisplayName}`);
    let queueData: Partial<CommunicationQueue> = {
      recipient: eventMemberEmail,
      communicationMethod: 'email',
      messageType: 'co-owner-invite',
      origin: 'ecom',
      templateData: {
        organizer: {
          organizerUserId: event.createdByUserId ?? '',
          firstName: organizerDisplayName,
          lastName: '',
        },
        event: {
          eventName: event.eventName,
          eventDate: convertUnixTimestampToPrettyDate(event.eventDate),
        },
        coOwner: {
          firstName: coOwnerFirstName,
          lastName: coOwnerLastName,
          phoneNumber: coOwnerPhone,
        },
        inviteUrl: inviteUrl,
      },
    };

    return this.sendToCommunicationQueue(queueData);
  }

  public queueEmailDealerInvite(
    origin: Site,
    firstName: string,
    lastName: string,
    email: string,
    documentId?: string,
    displayName?: string,
    userId?: string,
    // origin: string = 'accounting-portal'
  ) {
    let inviteUrl: string = `${this.environment.dealer_prefix_for_email_and_sms_communication}/register/${documentId}/${email}`;

    let queueData: Partial<CommunicationQueue> = {
      recipient: email,
      communicationMethod: 'email',
      messageType: 'dealer-invite',
      origin: origin,
      templateData: {
        dealer: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          documentId: documentId,
          displayName: displayName,
          userId: userId,
        },
        inviteUrl: inviteUrl,
      },
    };

    console.log(inviteUrl);

    return from(this.sendToCommunicationQueue(queueData));
  }

  public queueAddNewDealer(
    email: string,
    firstName: string,
    lastName: string,
    dealerId: string,
  ) {
    const dealerUrl = `${this.environment.dealer_prefix_for_email_and_sms_communication}/dashboard`;

    let queueData: Partial<CommunicationQueue> = {
      recipient: email,
      communicationMethod: 'email',
      messageType: 'additional-dealer',
      origin: origin,
      templateData: {
        dealer: {
          firstName,
          lastName,
          dealerId,
          url: dealerUrl,
        },
      },
    };
    return from(this.sendToCommunicationQueue(queueData));
  }

  /**
   * Sends a CommunicationQueue object to the Communication Queue API, which then adds the doc to the ComQ.
   * @param queueData the CommunicationQueue object to send to the API.
   * @returns a promise that resolves to the CommunicationQueue object that was sent to the API.
   */
  public sendToCommunicationQueue(
    queueData: Partial<CommunicationQueue>,
  ): Promise<Partial<CommunicationQueue>> {
    return firstValueFrom(
      this.httpClient
        .post<Partial<CommunicationQueue>>(
          this.environment.communication_queue_rest_api_server_url + '/',
          queueData,
          {
            ...requireAppCheck,
            headers: this.reqHeader,
          },
        )
        .pipe(
          tap((res) =>
            console.log(
              'CommunicationQueueService.sendToCommunicationQueue() response:',
              res,
            ),
          ),
          catchError((err: HttpErrorResponse) => {
            console.error(err.error);
            throw err;
          }),
        ),
    );
  }
}
