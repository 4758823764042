<mat-spinner *ngIf="showSpinner"></mat-spinner>

<div *ngIf="accountCommissionGrouping">
  <div class="nav-bar hide-on-print">
    <button mat-flat-button color="primary" (click)="goBack()">
      <div class="button-context">
        <mat-icon class="prev">chevron_left</mat-icon>PREV
      </div>
    </button>
    <div>
      <strong>Commission Account Number</strong><br />
      <span class="nav-text-small">{{
        accountCommissionGrouping.commissionAccountNumber
      }}</span>
    </div>
    <div>
      <strong>Time Period</strong><br />
      <span
        class="nav-text-small"
        *ngIf="accountCommissionGrouping.approvedFrom !== undefined"
        >{{
          accountCommissionGrouping.approvedFrom * 1000
            | date: "shortDate" : "UTC"
        }}
        to
      </span>

      <span
        class="nav-text-small"
        *ngIf="accountCommissionGrouping.approvedTo !== undefined"
        >{{
          accountCommissionGrouping.approvedTo * 1000
            | date: "shortDate" : "UTC"
        }}
      </span>
    </div>
    <div>
      <strong>Approved By</strong><br />
      <span class="nav-text-small">{{
        accountCommissionGrouping.approvedBy
      }}</span>
    </div>
    <div>
      <strong>Approved Date</strong><br />
      <span
        class="nav-text-small"
        *ngIf="accountCommissionGrouping.approvedOn !== undefined"
        >{{
          accountCommissionGrouping.approvedOn * 1000
            | date: "shortDate" : "UTC"
        }}</span
      >
    </div>
    <div>
      <mat-icon (click)="printPreview()" color="primary" style="cursor: pointer"
        >print</mat-icon
      >
    </div>
  </div>

  <!-- <div class="title-container">
    <h1>Commission Detail</h1>
    <mat-icon (click)="printPreview()">print</mat-icon>
  </div> -->

  <div class="logo-header">
    <img
      src="assets/logo.png"
      style="width: 200px; height: auto"
      alt="JFW - Jim's Formal Wear"
    /><br />
    <h2>Dealer Commission Summary</h2>
  </div>
  <div class="body">
    <p>
      In keeping with your partnership, you are receiving this commission check
      because of recently completed orders on our JFW website.
    </p>
    <p class="no-margin">
      Account Number:
      <strong>{{ accountCommissionGrouping.commissionAccountNumber }}</strong>
    </p>
    <p class="no-margin">
      Commission Payment Amount:
      <strong>{{ totalCommission | currency }}</strong>
    </p>
    <p class="no-margin">
      Commission Payment Period:
      <strong
        ><span *ngIf="accountCommissionGrouping.approvedFrom !== undefined">{{
          accountCommissionGrouping.approvedFrom * 1000
            | date: "shortDate" : "UTC"
        }}</span>
        to
        <span *ngIf="accountCommissionGrouping.approvedTo !== undefined">{{
          accountCommissionGrouping.approvedTo * 1000
            | date: "shortDate" : "UTC"
        }}</span></strong
      >
    </p>
    <ul>
      <p><u>Commission Detail</u></p>
      <p *ngIf="referralCommissionTotal > 0">
        Referral Commissions: {{ referralCommissionTotal | currency }}<br />
        <span style="font-size: 0.8em"
          >(to see details, navigate to dealers.jimsfw.com > Reports:
          Statements)</span
        >
      </p>
      <p *ngIf="customLinkCommissionTotal > 0">
        Custom Link Commissions: {{ customLinkCommissionTotal | currency
        }}<br />
        <span style="font-size: 0.8em"
          >(online events generated from your custom link)</span
        >
      </p>
      <p *ngIf="organicCommissionTotal > 0">
        Organic Commissions: {{ organicCommissionTotal | currency }}<br />
        <span style="font-size: 0.8em"
          >(online events created in your neighborhood)</span
        >
      </p>
      <p *ngIf="pastDueBalance !== 0">
        Past Due Balance Applied: {{ pastDueBalance | currency }}
      </p>
    </ul>
    <p style="margin-top: 20px">
      If you have questions, please contact our Accounting Department at (618)
      224-9211.
    </p>
    <p>Thank you for being a JFW Retailer!</p>
    <p class="no-margin">Sincerly,</p>
    <p class="no-margin">Your Friends from Jim's Formalwear</p>
  </div>
  <!--
  <div class="flex-container">
    <mat-list class="flex-item">
      <mat-list-item>
        <span matListItemTitle>Commission Account Number</span>
        <span matListItemLine>{{
          accountCommissionGrouping.commissionAccountNumber === ""
            ? "N/A"
            : accountCommissionGrouping.commissionAccountNumber
        }}</span>
      </mat-list-item>

      <mat-list-item>
        <span matListItemTitle>Time Period</span>
        <span matListItemLine>
          {{
            accountCommissionGrouping.approvedFrom
              ? (accountCommissionGrouping.approvedFrom * 1000
                | date : "shortDate")
              : "N/A"
          }}
          to
          {{
            accountCommissionGrouping.approvedTo
              ? (accountCommissionGrouping.approvedTo * 1000
                | date : "shortDate")
              : "N/A"
          }}
        </span>
      </mat-list-item>

      <mat-list-item>
        <span matListItemTitle>Approved By</span>
        <span matListItemLine>
          {{ accountCommissionGrouping.approvedBy }}
        </span>
      </mat-list-item>

      <mat-list-item>
        <span matListItemTitle>Approved Date</span>
        <span matListItemLine>
          {{
            accountCommissionGrouping.approvedOn
              ? (accountCommissionGrouping.approvedOn * 1000
                | date : "shortDate")
              : "N/A"
          }}
        </span>
      </mat-list-item>

      <mat-list-item>
        <span matListItemTitle>Commission Payment</span>
        <span matListItemLine>
          {{ accountCommissionGrouping.paidCommission | currency }}
        </span>
      </mat-list-item>
    </mat-list>
  </div> -->
</div>
