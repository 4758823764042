<div class="container" *ngIf="!showSpinner">
  <div>
    <button mat-flat-button (click)="goBack()">
      <span><mat-icon class="prev">chevron_left</mat-icon>PREV</span>
    </button>
    <h1>Pay Approved Commissions</h1>
  </div>

  <div class="commissions" *ngIf="this.accountCommissionGroups !== undefined">
    <table
      mat-table
      [dataSource]="this.accountCommissionGroups"
      class="mat-elevation-z8"
    >
      <ng-container matColumnDef="commissionAccountNumber">
        <th mat-header-cell *matHeaderCellDef>Commission Account Number</th>
        <td mat-cell *matCellDef="let commission">
          {{ commission.commissionAccountNumber }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="approvedOn">
        <th mat-header-cell *matHeaderCellDef>Approved Date</th>
        <td mat-cell *matCellDef="let commission">
          {{ commission.approvedOn * 1000 | date: "shortDate" : "UTC" }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="approvedBy">
        <th mat-header-cell *matHeaderCellDef>Approved By</th>
        <td mat-cell *matCellDef="let commission">
          {{ commission.approvedBy }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="timePeriod">
        <th mat-header-cell *matHeaderCellDef>Time Period</th>
        <td mat-cell *matCellDef="let commission">
          {{ commission.approvedFrom * 1000 | date: "shortDate" : "UTC" }} to
          {{ commission.approvedTo * 1000 | date: "shortDate" : "UTC" }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="totalCommission">
        <th mat-header-cell *matHeaderCellDef>Approved Commission</th>
        <td mat-cell *matCellDef="let commission">
          {{ getTotalCommissionsToPay(commission) | currency }}
        </td>

        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="process">
        <th mat-header-cell *matHeaderCellDef>Process</th>
        <td mat-cell *matCellDef="let commission; let i = index">
          <mat-checkbox (click)="toggleProcessGroup(i)"></mat-checkbox>
        </td>
        <td mat-footer-cell *matFooterCellDef>
          <button mat-raised-button (click)="processAccountGroupings()">
            Submit
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>
  </div>
</div>

<mat-spinner *ngIf="showSpinner"></mat-spinner>
